import React from 'react';
import { graphql } from 'gatsby';

import MainContentWrapper from '../../components/MainContentWrapper';
import SEO from '../../components/SEO';
import MarkdownHTMLAst from '../../components/MarkdownHTMLAst';

const CookiePolicy = ({ data }) => {
  return (
    <>
      <SEO
        seo={{
          title: 'Cookie Policy',
          metas: [
            {
              name: 'description',
              content: 'DRD event decorations cookie policy',
            },
          ],
        }}
      />
      <MainContentWrapper>
        <MarkdownHTMLAst data={data.markdownRemark.htmlAst.children} />
      </MainContentWrapper>
    </>
  );
};

export const query = graphql`
  query cookiePolicyQuery {
    markdownRemark(frontmatter: { type: { eq: "cookie policy" } }) {
      htmlAst
    }
  }
`;

export default CookiePolicy;
